<div style="padding: 30px">
  <div class="effect-card card">
    <div style="font-family: Roboto; font-weight: 500; border-bottom: none" class="card-header card-title"
      >Gene Symbol</div
    >
    <div class="card-block">
      <div class="input-group panel" style="padding: 15px; align-items: baseline">
        <input
          matAutocompleteOrigin
          #geneBrowserSearch="matAutocompleteOrigin"
          #geneBrowserSearchTrigger="matAutocompleteTrigger"
          matInput
          [matAutocomplete]="geneDropdown"
          [matAutocompleteConnectedTo]="geneBrowserSearch"
          #searchBox
          id="search-box"
          placeholder="Search gene"
          autocomplete="off"
          spellcheck="false"
          [(ngModel)]="geneSymbol"
          (keyup)="searchBoxInput$.next(geneSymbol)"
          (focus)="reset()"
          (keydown.enter)="submitGeneRequest(); geneBrowserSearchTrigger.closePanel()" />

        <mat-autocomplete autoActiveFirstOption #geneDropdown="matAutocomplete" class="gene-suggestions-dropdown">
          <mat-option
            *ngFor="let suggestion of geneSymbolSuggestions"
            class="dropdown-item"
            type="button"
            (click)="selectGeneSymbol(suggestion)"
            (onSelectionChange)="selectGeneSymbol(suggestion); submitGeneRequest()"
            [value]="suggestion">
            <span>{{ suggestion }}</span>
          </mat-option>
        </mat-autocomplete>

        <div style="padding-left: 16px">
          <label onmousedown="event.preventDefault()" (click)="toggleCodingOnly($event)">
            <input
              style="margin-right: 6px; pointer-events: none"
              type="checkbox"
              id="coding-only-checkbox"
              [(ngModel)]="summaryVariantsFilter.codingOnly" />Coding only</label
          >
        </div>
      </div>

      <div
        [hidden]="!showError"
        class="alert alert-danger"
        role="alert"
        style="display: inline-block; margin: 14px; margin-top: 0px; width: 400px">
        <span>No such gene found!</span>
      </div>
    </div>
  </div>

  <div style="margin-top: 15px">
    <input type="button" style="width: 75px" class="btn btn-md btn-primary" value="Go" (click)="submitGeneRequest()" />
  </div>

  <div *ngIf="showResults">
    <div #filters id="filters" style="margin-left: 0px">
      <div id="affected-status-filters" class="effect-card card filter" *ngIf="geneBrowserConfig.hasAffectedStatus">
        <div class="card-header card-title">Affected Status</div>
        <div class="card-block">
          <div *ngFor="let affectedStatus of affectedStatusValues; let i = index" class="checkbox">
            <label class="filter-label">
              <input
                #affectedStatusCheckbox
                checked
                type="checkbox"
                id="affectedStatus{{ affectedStatus }}-checkbox"
                (change)="checkAffectedStatus(affectedStatus, affectedStatusCheckbox.checked); updateVariants()" />
              <span
                class="pedigree-icon"
                [style.background-color]="getAffectedStatusColor(affectedStatus)"
                style="opacity: 0.75"></span>
              <span
                style="display: inline-block; vertical-align: middle; line-height: 95%"
                [ngStyle]="{
                  width:
                    affectedStatus === 'Affected only'
                      ? '70px'
                      : affectedStatus === 'Unaffected only'
                      ? '80px'
                      : affectedStatus === 'Affected and unaffected'
                      ? '100px'
                      : {}
                }">
                <span>{{ affectedStatus }}</span>
              </span>
            </label>
          </div>
        </div>
      </div>

      <div id="effect-types-filters" class="effect-card card filter">
        <div class="card-header card-title">Effect Types</div>
        <div class="card-block">
          <div *ngFor="let effect of effectTypeValues; let i = index" class="checkbox">
            <label class="filter-label">
              <input
                #effectTypeCheckbox
                type="checkbox"
                [checked]="this.summaryVariantsFilter.isEffectTypeSelected(effect)"
                (change)="checkEffectType(effect, effectTypeCheckbox.checked); updateVariants()" />
              <svg id="{{ effect }}" width="20" height="20"></svg>
              <span>{{ effect }}</span>
            </label>
          </div>
        </div>
      </div>

      <div id="inheritance-types-filters" class="effect-card card filter">
        <div class="card-header card-title">Inheritance Types</div>
        <div class="card-block">
          <div class="checkbox">
            <label class="filter-label">
              <input
                #denovoCheckbox
                style="margin-right: 5px"
                checked
                type="checkbox"
                (click)="checkShowDenovo(denovoCheckbox.checked); updateVariants()" />
              <svg id="denovo" width="120" height="20"></svg>
              <span>Denovo</span>
            </label>
          </div>
          <div class="checkbox">
            <label class="filter-label">
              <input
                #transmittedCheckbox
                checked
                type="checkbox"
                (click)="checkShowTransmitted(transmittedCheckbox.checked); updateVariants()" />
              <svg id="transmitted" width="125" height="20"></svg>
              <span>Transmitted</span>
            </label>
          </div>
        </div>
      </div>

      <div id="variant-types-filters" class="effect-card card filter">
        <div class="card-header card-title">Variant Types</div>
        <div class="card-block">
          <div *ngFor="let variantType of variantTypeValues; let i = index" class="checkbox">
            <label class="filter-label">
              <input
                #variantTypeCheckbox
                checked
                type="checkbox"
                (change)="checkVariantType(variantType, variantTypeCheckbox.checked); updateVariants()" />
              <span>{{ variantType }}</span>
            </label>
          </div>
        </div>
      </div>
    </div>

    <div>
      <gpf-gene-plot
        [gene]="selectedGene"
        [variantsArray]="summaryVariantsArrayFiltered"
        [condenseIntrons]="summaryVariantsFilter.codingOnly"
        [frequencyDomain]="[geneBrowserConfig.domainMin, geneBrowserConfig.domainMax]"
        [yAxisLabel]="geneBrowserConfig.frequencyName || geneBrowserConfig.frequencyColumn"
        [summaryVariantsCount]="summaryVariantsArray.totalSummaryAllelesCount"
        (selectedRegion)="setSelectedRegion($event)"
        (selectedFrequencies)="setSelectedFrequencies($event)"
        [selectedGene]="selectedGene"
        (downloadSummaryVariants)="onSubmitSummary()">
      </gpf-gene-plot>

      <fieldset id="unique-family-variants-block" class="form-block">
        <div id="unique-family-variants">
          <label
            for="unique-family-variants-checkbox"
            class="nav-title-custom"
            style="width: unset; margin-right: unset; padding-right: 10px"
            >Unique family variants</label
          >
          <input
            id="unique-family-variants-checkbox"
            type="checkbox"
            [disabled]="!isUniqueFamilyFilterEnabled"
            (click)="checkUniqueFamilyVariantsFilter()" />
        </div>
        <div id="family-variants-wrapper">
          <span title="Family variants">
            <span class="d-none d-xl-inline">Family variants:</span>
            <span class="d-none d-md-inline d-xl-none d-xxl-none">FV:</span>
            <span id="family-variants-count">
              <span>{{ displayVariantsCount() }} / {{ this.summaryVariantsArray.totalFamilyVariantsCount }}</span>
            </span>
          </span>
          <div *ngIf="selectedGene">
            <form
              id="download-family-form"
              (ngSubmit)="onSubmit($event)"
              action="{{ configService.baseUrl }}genotype_browser/query"
              method="post">
              <input name="queryData" type="hidden" />
              <button
                [disabled]="!this.summaryVariantsArrayFiltered?.totalFamilyVariantsCount"
                class="btn btn-md btn-primary btn-right"
                id="download-family-variants-button"
                type="submit">
                <span class="material-symbols-outlined">download</span>
              </button>
            </form>
            <form
              id="download-summary-form"
              action="{{ configService.baseUrl }}gene_view/download_summary_variants"
              method="post"
              style="display: hidden">
              <input name="queryData" type="hidden" />
            </form>
          </div>
        </div>
      </fieldset>

      <div class="row">
        <div class="col-6">
          <gpf-loading-spinner
            *ngIf="selectedGene"
            [loadingFinished]="familyVariantsLoaded"
            [count]="variantsCountDisplay"
            [verboseMode]="true">
          </gpf-loading-spinner>
        </div>
      </div>

      <gpf-genotype-preview-table
        [columns]="selectedDataset?.genotypeBrowserConfig?.tableColumns"
        [genotypePreviewVariantsArray]="genotypePreviewVariantsArray"
        [legend]="legend">
      </gpf-genotype-preview-table>
    </div>
  </div>
</div>
