<div *ngIf="isVisible" id="unique-family-variants-block" class="form-block">
  <div class="card">
    <ul ngbNav class="navbar bg-light nav-pills">
      <label for="unique-family-variants-checkbox" class="nav-title-custom" id="unique-family-variants-label"
        >Unique family variants</label
      >
      <li ngbNavItem id="item">
        <input id="unique-family-variants-checkbox" type="checkbox" [(ngModel)]="filterValue" />
      </li>
    </ul>
  </div>
</div>
