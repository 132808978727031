<div style="background-color: #f8d7da">
  <div class="modal-body">
    <p style="color: #721c24; text-align: center">An error occurred.</p>
  </div>
  <div class="modal-footer justify-content-center">
    <button (click)="closeModal()" style="align-content: center" type="button" class="btn btn-secondary"
      >Dismiss</button
    >
  </div>
</div>
