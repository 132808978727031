<div class="regions-block form-block">
  <div class="card">
    <ul ngbNav #nav="ngbNav" (navChange)="onNavChange()" class="navbar bg-light nav-pills" id="regions-list">
      <div class="nav-title-custom">Regions</div>
      <li [ngbNavItem]="'allRegions'">
        <a ngbNavLink>All</a>
      </li>
      <li [ngbNavItem]="'regionsFilter'" id="regions-filter">
        <a ngbNavLink>Regions Filter</a>
        <ng-template ngbNavContent>
          <gpf-regions-filter [genome]="genome"></gpf-regions-filter>
        </ng-template>
      </li>
    </ul>
    <div [ngbNavOutlet]="nav" class="card-block"></div>
  </div>
</div>
