<div *ngIf="showTemplate" class="profile-container">
  <div class="form-block">
    <div class="card">
      <ul ngbNav #nav="ngbNav" (navChange)="updateQueries()" class="navbar bg-light nav-pills" id="user-profile-list">
        <div class="nav-title-custom">User profile</div>

        <li ngbNavItem>
          <a ngbNavLink id="saved-queries-button">Saved queries</a>
          <ng-template ngbNavContent>
            <div class="table-wrapper" id="genotype-browser-table">
              <div class="table-title">Genotype Browser</div>
              <gpf-saved-queries-table [queries]="genotypeQueries"></gpf-saved-queries-table>
            </div>
            <div class="table-wrapper" id="enrichment-tool-table">
              <div class="table-title">Enrichment Tool</div>
              <gpf-saved-queries-table [queries]="enrichmentQueries"></gpf-saved-queries-table>
            </div>
            <div class="table-wrapper">
              <div class="table-title" id="phenotype-tool-table">Phenotype Tool</div>
              <gpf-saved-queries-table [queries]="phenotoolQueries"></gpf-saved-queries-table>
            </div>
          </ng-template>
        </li>

        <li ngbNavItem>
          <a ngbNavLink id="federation-token-button">Federation tokens</a>
          <ng-template ngbNavContent>
            <gpf-federation-credentials></gpf-federation-credentials>
          </ng-template>
        </li>
      </ul>
      <div [ngbNavOutlet]="nav" class="card-block"></div>
    </div>
  </div>
</div>
