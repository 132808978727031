<div class="panel" id="person-ids-panel">
  <textarea
    #textArea
    type="text"
    [ngModel]="personIds.personIds"
    (ngModelChange)="setPersonIds($event)"
    class="form-control"
    spellcheck="false">
  </textarea>
  <gpf-errors-alert [errors]="errors"></gpf-errors-alert>
</div>
