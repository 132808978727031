<div *ngIf="selectedDataset" class="content">
  <nav class="navbar navbar-custom navbar-toggleable-md navbar-expand-lg navbar-light bg-light">
    <ul class="navbar-nav">
      <li class="nav-item">
        <div class="btn-group">
          <div ngbDropdown>
            <button
              ngbDropdownToggle
              class="dataset-selector btn btn-outline-secondary navbar-brand"
              type="button"
              id="datasets-dropdown-menu-button"
              aria-haspopup="true"
              aria-expanded="false">
              <span *ngIf="datasetsLoading">Loading datasets...</span>
              <span *ngIf="!datasetsLoading" id="selected-dataset-name">{{ selectedDataset.name }}</span>
            </button>
            <div
              ngbDropdownMenu
              class="dataset-selector"
              aria-labelledby="datasets-dropdown-menu-button"
              style="z-index: 199">
              <ng-container *ngIf="!datasetsLoading">
                <gpf-dataset-node *ngFor="let tree of datasetTrees" [datasetNode]="tree"></gpf-dataset-node>
              </ng-container>
            </div>
          </div>
        </div>
      </li>
      <li
        [class.disabled-tool]="!(selectedDataset.geneBrowser.enabled && selectedDataset.accessRights)"
        class="nav-item">
        <a class="nav-link" routerLink="{{ toolPageLinks.geneBrowser }}" routerLinkActive="active">Gene Browser</a>
      </li>
      <li [class.disabled-tool]="!(selectedDataset.genotypeBrowser && selectedDataset.accessRights)" class="nav-item">
        <a class="nav-link" routerLink="{{ toolPageLinks.genotypeBrowser }}" routerLinkActive="active"
          >Genotype Browser</a
        >
      </li>
      <li [class.disabled-tool]="!(selectedDataset.phenotypeBrowser && selectedDataset.accessRights)" class="nav-item">
        <a class="nav-link" routerLink="{{ toolPageLinks.phenotypeBrowser }}" routerLinkActive="active"
          >Phenotype Browser</a
        >
      </li>
      <li [class.disabled-tool]="!(selectedDataset.enrichmentTool && selectedDataset.accessRights)" class="nav-item">
        <a class="nav-link" routerLink="{{ toolPageLinks.enrichmentTool }}" routerLinkActive="active"
          >Enrichment Tool</a
        >
      </li>
      <li [class.disabled-tool]="!(selectedDataset.phenotypeTool && selectedDataset.accessRights)" class="nav-item">
        <a class="nav-link" routerLink="{{ toolPageLinks.phenotypeTool }}" routerLinkActive="active">Phenotype Tool</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" routerLink="{{ toolPageLinks.datasetDescription }}" routerLinkActive="active"
          >Dataset Description</a
        >
      </li>
      <li
        [class.disabled-tool]="!(selectedDataset.commonReport.enabled && selectedDataset.accessRights)"
        class="nav-item">
        <a class="nav-link" routerLink="{{ toolPageLinks.datasetStatistics }}" routerLinkActive="active"
          >Dataset Statistics</a
        >
      </li>
    </ul>
  </nav>

  <div
    *ngIf="registerAlertVisible && permissionDeniedPrompt"
    class="alert alert-danger"
    style="margin: 10px"
    id="permission-denied-prompt">
    <markdown [data]="permissionDeniedPrompt"></markdown>
  </div>

  <router-outlet
    *ngIf="selectedDataset.accessRights || selectedTool === toolPageLinks.datasetDescription"
    (activate)="routeChange()"
    class="content"></router-outlet>
</div>

<div
  *ngIf="selectedDataset === undefined"
  class="alert alert-danger"
  role="alert"
  style="display: inline-block; margin: 14px; margin-top: 0px; width: 400px">
  <span>No such dataset found!</span>
</div>
