<div style="padding: 20px" id="family-ids-panel">
  <textarea
    #textArea
    type="text"
    [ngModel]="familyIds.familyIds"
    (ngModelChange)="setFamilyIds($event)"
    class="form-control"
    spellcheck="false">
  </textarea>
  <gpf-errors-alert [errors]="errors"></gpf-errors-alert>
</div>
