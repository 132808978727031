<div *ngIf="!(userInfo$ | async)?.email" class="row justify-content-end">
  <div style="display: flex; justify-content: flex-end">
    <button (click)="login()" class="btn btn-secondary" id="log-in-button" type="button">
      <span>Log In</span>
    </button>
  </div>
</div>

<div *ngIf="(userInfo$ | async)?.email as email" style="display: flex; justify-content: flex-end">
  <span id="username-field" style="align-self: center">{{ email }}</span>
  <button class="btn btn-sm" id="log-out-button" (click)="logout()">Log Out</button>
</div>
