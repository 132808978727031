<div *ngIf="showLoading" class="overlay"></div>
<div *ngIf="showLoading">
  <div class="dna centered">
    <div class="ele">
      <div class="dot"></div>
    </div>
    <div class="ele">
      <div class="dot"></div>
    </div>
    <div class="ele">
      <div class="dot"></div>
    </div>
    <div class="ele">
      <div class="dot"></div>
    </div>
    <div class="ele">
      <div class="dot"></div>
    </div>
    <div class="ele">
      <div class="dot"></div>
    </div>
    <div class="ele">
      <div class="dot"></div>
    </div>
    <div class="ele">
      <div class="dot"></div>
    </div>
    <div class="ele">
      <div class="dot"></div>
    </div>
    <div class="ele">
      <div class="dot"></div>
    </div>
    <div class="ele">
      <div class="dot"></div>
    </div>
    <div class="ele">
      <div class="dot"></div>
    </div>
    <div class="ele">
      <div class="dot"></div>
    </div>
    <div class="ele">
      <div class="dot"></div>
    </div>
    <div class="ele">
      <div class="dot"></div>
    </div>
    <div class="ele">
      <div class="dot"></div>
    </div>
    <div class="ele">
      <div class="dot"></div>
    </div>
    <div class="ele">
      <div class="dot"></div>
    </div>
    <div class="ele">
      <div class="dot"></div>
    </div>
    <div class="ele">
      <div class="dot"></div>
    </div>
    <div class="ele">
      <div class="dot"></div>
    </div>
    <div class="ele">
      <div class="dot"></div>
    </div>
    <div class="ele">
      <div class="dot"></div>
    </div>
    <div class="ele">
      <div class="dot"></div>
    </div>
    <div class="ele">
      <div class="dot"></div>
    </div>
    <div class="ele">
      <div class="dot"></div>
    </div>
    <div class="ele">
      <div class="dot"></div>
    </div>
    <div class="ele">
      <div class="dot"></div>
    </div>
    <div class="ele">
      <div class="dot"></div>
    </div>
    <div class="ele">
      <div class="dot"></div>
    </div>
    <div class="ele">
      <div class="dot"></div>
    </div>
    <div class="ele">
      <div class="dot"></div>
    </div>
    <div class="ele">
      <div class="dot"></div>
    </div>
    <div class="ele">
      <div class="dot"></div>
    </div>
    <div class="ele">
      <div class="dot"></div>
    </div>
    <div class="ele">
      <div class="dot"></div>
    </div>
    <div class="ele">
      <div class="dot"></div>
    </div>
    <div class="ele">
      <div class="dot"></div>
    </div>
    <div class="ele">
      <div class="dot"></div>
    </div>
    <div class="ele">
      <div class="dot"></div>
    </div>
    <div class="ele">
      <div class="dot"></div>
    </div>
    <div class="ele">
      <div class="dot"></div>
    </div>
    <div class="ele">
      <div class="dot"></div>
    </div>
    <div class="ele">
      <div class="dot"></div>
    </div>
    <div class="ele">
      <div class="dot"></div>
    </div>
    <div class="ele">
      <div class="dot"></div>
    </div>
    <div class="ele">
      <div class="dot"></div>
    </div>
    <div class="ele">
      <div class="dot"></div>
    </div>
    <div class="ele">
      <div class="dot"></div>
    </div>
    <div class="ele">
      <div class="dot"></div>
    </div>
    <div class="ele">
      <div class="dot"></div>
    </div>
    <div class="ele">
      <div class="dot"></div>
    </div>
    <div class="ele">
      <div class="dot"></div>
    </div>
    <div class="ele">
      <div class="dot"></div>
    </div>
    <div class="ele">
      <div class="dot"></div>
    </div>
    <div class="ele">
      <div class="dot"></div>
    </div>
    <div class="ele">
      <div class="dot"></div>
    </div>
    <div class="ele">
      <div class="dot"></div>
    </div>
    <div class="ele">
      <div class="dot"></div>
    </div>
    <div class="ele">
      <div class="dot"></div>
    </div>
  </div>
  <button id="cancel-request-button" (click)="cancelLoading()" type="button" class="btn btn-md btn-primary centered"
    >Cancel request</button
  >
</div>
