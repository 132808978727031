<ng-template #confirmation let-options="options">
  <div [ngClass]="['popover', 'show', 'bs-popover-' + options.placement, options.popoverClass]">
    <div style="left: calc(50% - 5px)" class="arrow"></div>
    <h3 class="popover-header" [innerHTML]="options.title"></h3>
    <div class="popover-body">
      <p [innerHTML]="options.popoverMessage"></p>
      <div class="row">
        <div class="col-xs-12 col-12 text-center">
          <button
            *ngIf="!options.hideConfirmButton"
            [mwlFocus]="options.focusButton === 'confirm'"
            [class]="'btn btn-' + options.confirmButtonType"
            (click)="options.onConfirm({ clickEvent: $event })"
            [innerHtml]="options.confirmText"></button>
          <button
            *ngIf="!options.hideCancelButton"
            [mwlFocus]="options.focusButton === 'cancel'"
            [class]="'btn btn-' + options.cancelButtonType"
            (click)="options.onCancel({ clickEvent: $event })"
            [innerHtml]="options.cancelText"></button>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<div class="form-block">
  <div class="card">
    <div class="navbar navbar-toggleable-md navbar-light navbar-expand-lg bg-light">
      <div class="form-block-title">{{ edit ? 'Edit user' : 'Create user' }}</div>
    </div>
    <div class="col-12 mt-3"></div>
    <form *ngIf="user$ | async as user">
      <div class="row justify-content-center">
        <div class="col-4">
          <div class="form-group">
            <label for="email">Email</label>
            <input
              #emailInput
              name="email"
              id="email"
              class="form-control"
              [(ngModel)]="user.email"
              type="text"
              [readonly]="edit"
              required
              spellcheck="false" />
          </div>
        </div>
      </div>

      <div class="row justify-content-center">
        <div class="col-4">
          <div class="form-group">
            <label for="name">Name</label>
            <input
              #nameInput
              name="name"
              id="name"
              class="form-control"
              [(ngModel)]="user.name"
              type="text"
              spellcheck="false" />
          </div>
        </div>
      </div>

      <div class="row justify-content-center">
        <div class="col-4">
          <div class="form-group">
            <label for="groups">Default groups</label>
            <div style="cursor: not-allowed" class="groupsInput">
              <div style="pointer-events: none; opacity: 0.7; cursor: not-allowed">
                <ng-multiselect-dropdown [settings]="dropdownSettings" [placeholder]="getDefaultGroups().join(', ')">
                </ng-multiselect-dropdown>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div *ngIf="groups$ | async as groups" class="row justify-content-center">
        <div class="col-4">
          <div class="form-group">
            <label for="groups">Add groups</label>
            <div class="groupsInput">
              <gpf-user-groups-selector
                [allInputtedGroups]="groups"
                [userGroups]="user.groups"
                [defaultGroups]="getDefaultGroups()">
              </gpf-user-groups-selector>
            </div>
          </div>
        </div>
      </div>

      <div *ngIf="createUserError" class="row justify-content-center" style="margin-bottom: 30px">
        <div class="col-4">
          <div style="margin-bottom: 0px" class="alert alert-danger">{{ createUserError }}</div>
        </div>
      </div>

      <div class="row justify-content-end">
        <div class="col-4">
          <button
            #ele
            class="btn"
            mwlConfirmationPopover
            confirmText="Back"
            title="Remove password"
            [appendToBody]="true"
            [customTemplate]="confirmation"
            [popoverMessage]="'Changes on this screen will not be saved.'"
            (confirm)="goBack()"
            >Back</button
          >
          <button class="btn btn-primary" type="submit" (click)="submit(user)">Submit</button>
        </div>
      </div>
      <div class="col-12 mt-3"></div>
    </form>
  </div>
</div>
