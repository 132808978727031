<td>{{ label }}</td>
<td
  class="link-td"
  (click)="goToQuery(effectResult.all.countFilter, true)"
  (middleclick)="goToQuery(effectResult.all.countFilter, true)"
  [style.background-color]="effectResult.all | getEnrichmentBackgroundColor">
  <a class="link" [style.color]="effectResult.all | getEnrichmentBackgroundColor | contrastAdjust">{{
    effectResult.all.count | numberWithExp : '1.0-2'
  }}</a>
</td>
<td
  class="link-td"
  (click)="goToQuery(effectResult.all.overlapFilter)"
  (middleclick)="goToQuery(effectResult.all.overlapFilter)"
  [style.background-color]="effectResult.all | getEnrichmentBackgroundColor">
  <a class="link" [style.color]="effectResult.all | getEnrichmentBackgroundColor | contrastAdjust">{{
    effectResult.all.overlapped | numberWithExp : '1.0-2'
  }}</a>
</td>
<td [style.background-color]="effectResult.all | getEnrichmentBackgroundColor">
  <span>{{ effectResult.all.expected | numberWithExp : '1.2-2' }}</span>
</td>
<td [style.background-color]="effectResult.all | getEnrichmentBackgroundColor">
  <span>{{ effectResult.all.pvalue | numberWithExp : '1.2-4' }}</span>
</td>
<td
  class="link-td"
  (click)="goToQuery(effectResult.male.countFilter, true)"
  (middleclick)="goToQuery(effectResult.male.countFilter, true)"
  [style.background-color]="effectResult.male | getEnrichmentBackgroundColor">
  <a class="link" [style.color]="effectResult.male | getEnrichmentBackgroundColor | contrastAdjust">{{
    effectResult.male.count | numberWithExp : '1.0-2'
  }}</a>
</td>
<td
  class="link-td"
  (click)="goToQuery(effectResult.male.overlapFilter)"
  (middleclick)="goToQuery(effectResult.male.overlapFilter)"
  [style.background-color]="effectResult.male | getEnrichmentBackgroundColor">
  <a class="link" [style.color]="effectResult.male | getEnrichmentBackgroundColor | contrastAdjust">{{
    effectResult.male.overlapped | numberWithExp : '1.0-2'
  }}</a>
</td>
<td [style.background-color]="effectResult.male | getEnrichmentBackgroundColor">
  <span>{{ effectResult.male.expected | numberWithExp : '1.2-2' }}</span>
</td>
<td [style.background-color]="effectResult.male | getEnrichmentBackgroundColor">
  <span>{{ effectResult.male.pvalue | numberWithExp : '1.2-4' }}</span>
</td>
<td
  class="link-td"
  (click)="goToQuery(effectResult.female.countFilter, true)"
  (middleclick)="goToQuery(effectResult.female.countFilter, true)"
  [style.background-color]="effectResult.female | getEnrichmentBackgroundColor">
  <a class="link" [style.color]="effectResult.female | getEnrichmentBackgroundColor | contrastAdjust">{{
    effectResult.female.count | numberWithExp : '1.0-2'
  }}</a>
</td>
<td
  class="link-td"
  (click)="goToQuery(effectResult.female.overlapFilter)"
  (middleclick)="goToQuery(effectResult.female.overlapFilter)"
  [style.background-color]="effectResult.female | getEnrichmentBackgroundColor">
  <a class="link" [style.color]="effectResult.female | getEnrichmentBackgroundColor | contrastAdjust">{{
    effectResult.female.overlapped | numberWithExp : '1.0-2'
  }}</a>
</td>
<td [style.background-color]="effectResult.female | getEnrichmentBackgroundColor">
  <span>{{ effectResult.female.expected | numberWithExp : '1.2-2' }}</span>
</td>
<td [style.background-color]="effectResult.female | getEnrichmentBackgroundColor">
  <span>{{ effectResult.female.pvalue | numberWithExp : '1.2-4' }}</span>
</td>
