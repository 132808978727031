<div style="padding: 10px; min-width: 400px; display: grid">
  <div style="width: 100%; padding: 1px 0">
    <button
      id="check-uncheck-all-button"
      type="button"
      class="btn btn-default"
      style="float: left; position: relative; width: 30%; margin-right: 2%"
      (click)="toggleCheckingAll()"
      >{{ buttonLabel }}</button
    >
    <div class="search-input-wrapper" style="width: 68%">
      <input
        #searchInput
        class="search-input form-control"
        type="text"
        name="search"
        [attr.placeholder]="searchPlaceholder"
        [(ngModel)]="searchText"
        (ngModelChange)="filterItems($event)"
        autocomplete="off"
        spellcheck="false" />
      <span
        class="material-symbols-outlined search-clear-icon"
        *ngIf="searchInput.value !== ''"
        (click)="searchInput.value = ''; searchText = ''; filterItems('')">close</span
      >
    </div>
  </div>

  <table cdkDropList (cdkDropListDropped)="drop($event)">
    <tr *ngFor="let column of filteredColumns" style="height: 30px">
      <td>
        <label cdkDrag style="width: 100%; padding-top: 4px; padding-bottom: 4px; padding-left: 6px">
          <input type="checkbox" [checked]="column.visibility" (change)="toggleItem(column, $event)" />
          <span style="padding-left: 10px">{{ column.displayName }}</span>
        </label>
      </td>
    </tr>
  </table>
</div>
