<div class="row">
  <div class="col-12 mt-3">
    <div class="grid-container" *ngIf="users">
      <div class="grid-header">User</div>
      <div class="grid-header">Has password</div>
      <div class="grid-header">Groups</div>
      <div class="grid-header">Datasets</div>
      <div class="grid-header">Actions</div>
      <ng-container *ngFor="let user of users">
        <div class="grid-cell" [attr.id]="user.email + '-user-cell'">
          <a>
            <div>
              <span *ngIf="user.name === '' && currentUserEdit !== user.id" id="add-user-name">Add username</span>
              <span *ngIf="user.name !== '' && currentUserEdit !== user.id" id="user-name">{{ user.name }}</span>
              <span
                *ngIf="currentUserEdit !== user.id"
                (click)="currentUserEdit = user.id"
                id="edit-icon"
                class="material-symbols-outlined"
                title="Edit"
                >edit</span
              >
              <div *ngIf="currentUserEdit === user.id" id="edit-wrapper">
                <input
                  #newNameBox
                  type="text"
                  [value]="user.name"
                  class="name-field search-input form-control my-0 py-1"
                  [attr.id]="user.email + '-new-name-input'"
                  spellcheck="false"
                  autocomplete="off"
                  pattern=".{3,}"
                  required
                  (keydown.enter)="edit(user, newNameBox.value)"
                  (keydown.escape)="currentUserEdit = -1" />
                <button id="cancel-button" (click)="currentUserEdit = -1" title="Cancel">
                  <span class="material-symbols-outlined sm bold red">close</span></button
                >
              </div>
            </div>
            <div>{{ user.email }}</div>
          </a>
        </div>

        <div class="grid-cell" [attr.id]="user.email + '-password-cell'">
          <span *ngIf="user.hasPassword">
            <span class="material-symbols-outlined">check</span>
          </span>
        </div>

        <div class="grid-cell list-cell" [attr.id]="user.email + '-groups-cell'">
          <div class="item-list" [attr.id]="user.email + '-groups-list'">
            <div class="item-wrapper" *ngFor="let group of user.groups" [attr.id]="group + '-list-item'">
              <span class="item" [title]="group">{{ group }}</span>
              <gpf-confirm-button
                *ngIf="!isDefaultGroup(user, group) && !(user.email === currentUserEmail && group === 'admin')"
                (clicked)="removeGroup(user, group)"
                class="remove-group-button"
                confirmText="Remove"
                title="Remove group"
                message="Remove group?"
                [iconStyle]="{ name: 'close', class: 'material-symbols-outlined sm bold' }">
              </gpf-confirm-button>
            </div>
          </div>
          <gpf-item-add-menu
            [getItems]="getGroupNamesFunction(user)"
            (addedItem)="addGroup(user, $event)"></gpf-item-add-menu>
        </div>

        <div class="grid-cell list-cell" [attr.id]="user.email + '-datasets-cell'">
          <div class="item-list" [attr.id]="user.email + '-datasets-list'">
            <div
              class="item"
              [title]="dataset.datasetName"
              *ngFor="let dataset of user.allowedDatasets"
              [attr.id]="dataset.datasetName + '-list-item'"
              >{{ dataset.datasetName }}</div
            >
          </div>
        </div>

        <div class="grid-cell" [attr.id]="user.email + '-actions-cell'">
          <span class="action-buttons">
            <gpf-confirm-button
              [attr.id]="user.email + '-reset-password-button'"
              (clicked)="resetPassword(user)"
              confirmText="Reset"
              title="Reset password"
              [message]="
                user.name +
                ' ' +
                user.email +
                ' password will be reset. An email with reset instructions will be sent and they won&lsquo;t be able to log in until they set a new password.'
              "
              [iconStyle]="{ name: 'refresh', class: 'material-symbols-outlined bold' }">
            </gpf-confirm-button>
            <gpf-confirm-button
              [attr.id]="user.email + '-delete-user-button'"
              *ngIf="currentUserEmail !== user.email"
              (clicked)="deleteUser(user)"
              confirmText="Delete"
              title="Delete user"
              [message]="user.name + ' ' + user.email + ' will be deleted. This action is irrevertible!'"
              [iconStyle]="{ name: 'close', class: 'material-symbols-outlined bold' }">
            </gpf-confirm-button>
          </span>
        </div>
      </ng-container>
    </div>
  </div>
</div>
