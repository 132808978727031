<span>{{ continuousFilterState.id }}:</span>
<gpf-pheno-measure-selector [datasetId]="datasetId" (selectedMeasureChange)="selectedMeasure = $event">
</gpf-pheno-measure-selector>

<gpf-continuous-filter
  *ngIf="selectedMeasure"
  [datasetId]="datasetId"
  [continuousFilterState]="continuousFilterState"
  [measureName]="selectedMeasure?.name"
  (updateFilterEvent)="updateFilterEvent.emit()"
  id="continuous-filter">
</gpf-continuous-filter>
