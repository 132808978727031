<div class="genotype-block form-block">
  <div class="row row-eq-height">
    <div *ngIf="dataset.genotypeBrowserConfig.hasPedigreeSelector" class="col-lg-3">
      <gpf-pedigree-selector [collections]="dataset.personSetCollections.collections"></gpf-pedigree-selector>
    </div>

    <div *ngIf="dataset.genotypeBrowserConfig.hasPresentInChild" class="col-lg-3">
      <gpf-present-in-child></gpf-present-in-child>
    </div>

    <div *ngIf="dataset.genotypeBrowserConfig.hasPresentInParent" class="col-lg-3">
      <gpf-present-in-parent></gpf-present-in-parent>
    </div>

    <div class="col-lg-6 panel-row">
      <gpf-effect-types [variantTypes]="variantTypesSet"></gpf-effect-types>
    </div>

    <div class="col-lg-3 panel-row">
      <gpf-gender></gpf-gender>
    </div>

    <div class="col-lg-3 panel-row">
      <gpf-variant-types [variantTypes]="variantTypesSet" [selectedVariantTypes]="selectedVariantTypesSet">
      </gpf-variant-types>
    </div>

    <div *ngIf="dataset.genotypeBrowserConfig.hasStudyTypes" class="col-lg-3">
      <gpf-study-types></gpf-study-types>
    </div>

    <div class="col-lg-3 panel-row">
      <gpf-inheritancetypes
        *ngIf="dataset.genotypeBrowserConfig.inheritanceTypeFilter?.length"
        [inheritanceTypes]="inheritanceTypeFilterSet"
        [selectedValues]="selectedInheritanceTypeFilterValuesSet">
      </gpf-inheritancetypes>
    </div>
  </div>
</div>
