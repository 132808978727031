<div class="panel" id="gene-symbols-panel">
  <textarea
    #textArea
    id="text-area"
    type="text"
    [ngModel]="geneSymbols.geneSymbols"
    (ngModelChange)="setGeneSymbols($event)"
    class="form-control"
    spellcheck="false"></textarea>
  <div [hidden]="geneSymbols.geneSymbols" class="alert alert-warning" role="alert">
    <span>Please insert at least one gene symbol.</span>
  </div>
</div>
