<div [ngClass]="{ 'fixed-height': !noScrollOptimization }" style="overflow-y: hidden">
  <ng-template [ngTemplateOutlet]="cellContent.contentTemplateRef" [ngTemplateOutletContext]="{ $implicit: data }">
  </ng-template>
  <span *ngIf="!cellContent.contentTemplateRef" [attr.title]="data[cellContent.field]">{{
    data[cellContent.field]
  }}</span>

  <div *ngFor="let child of cellContent.subcontentChildren" style="text-align: center">
    <ng-template [ngTemplateOutlet]="child.contentTemplateRef" [ngTemplateOutletContext]="{ $implicit: data }">
    </ng-template>
    <span *ngIf="!child.contentTemplateRef" [attr.title]="data[child.field]">{{ data[child.field] }}</span>
  </div>
</div>
