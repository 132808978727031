<svg:g
  #draggable
  [attr.transform]="'translate(' + x + ' ' + y + ')'"
  cursor="ew-resize"
  stroke-opacity="0"
  [attr.stroke-width]="15">
  <svg:line
    [attr.x1]="width / 2"
    [attr.x2]="width / 2"
    [attr.y2]="height"
    stroke="green"
    stroke-opacity="0.6"
    [attr.stroke-width]="width" />
  <svg:rect x="-10" width="22" height="90" style="opacity: 0" />
  <svg:path #triangle transform="translate(-4.5, 15) rotate(-90) scale(0.5)" stroke="black" fill="green" />
  <svg:path
    #triangle
    [attr.transform]="'translate(' + (width + 4.5) + ', 15) rotate(90) scale(0.5)'"
    stroke="black"
    fill="green" />
  <!-- prettier-ignore -->
  <svg:text *ngIf="!textOnRight" x="-10" y="15" dy=".35em" class="partitions-text" text-anchor="end">{{ text }}</svg:text>
  <!-- prettier-ignore -->
  <svg:text *ngIf="textOnRight" [attr.x]="width + 10" y="15" dy=".35em" class="partitions-text" text-anchor="start">{{ text }}</svg:text>
</svg:g>
