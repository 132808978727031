<div class="panel" style="display: flex; flex-wrap: wrap; justify-content: center; border-top: 0">
  <div class="col-sm-9" style="display: flex; padding: 0 12px 0 12px; align-items: center">
    <div class="select-wrapper">
      <select
        required
        class="form-control"
        [(ngModel)]="selectedGenomicScores"
        [attr.title]="selectedGenomicScores.desc"
        style="padding-right: 18px; text-overflow: ellipsis; white-space: nowrap">
        <option *ngFor="let genomicScores of genomicScoresArray" [ngValue]="genomicScores">
          <span>{{ genomicScores.desc ? genomicScores.desc : genomicScores.score }}</span>
        </option>
      </select>
      <span class="material-symbols-outlined select-dropdown-icon">arrow_drop_down</span>
    </div>
    <gpf-helper-modal
      style="height: 32px; margin-left: 4px"
      [style.visibility]="this.genomicScoreState.score.help ? 'visible' : 'hidden'"
      [modalContent]="this.genomicScoreState.score.help"
      [isMarkdown]="true"></gpf-helper-modal>
  </div>
  <div class="col-sm-11">
    <gpf-histogram
      *ngIf="selectedGenomicScores"
      [width]="800"
      [height]="145"
      [marginLeft]="180"
      [bins]="selectedGenomicScores.bins"
      [bars]="selectedGenomicScores.bars"
      [domainMin]="domainMin"
      [domainMax]="domainMax"
      [logScaleX]="selectedGenomicScores.logScaleX"
      [logScaleY]="selectedGenomicScores.logScaleY"
      [smallValuesDesc]="selectedGenomicScores.smallValuesDesc"
      [largeValuesDesc]="selectedGenomicScores.largeValuesDesc"
      [(rangeStart)]="rangeStart"
      [(rangeEnd)]="rangeEnd"
      style="display: block; margin-top: 17px">
    </gpf-histogram>
    <gpf-errors-alert [errors]="errors"></gpf-errors-alert>
  </div>
</div>
