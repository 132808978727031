<div class="pheno-tool-measure-block form-block">
  <div class="row">
    <div class="col-lg-6">
      <span>Proband Pheno Measure</span>
      <ng-container *ngIf="dataset">
        <gpf-pheno-measure-selector
          [datasetId]="dataset.id"
          (selectedMeasureChange)="measure = $event; clearCheckbox()"
          (measuresChange)="measuresUpdate($event)">
        </gpf-pheno-measure-selector>
      </ng-container>

      <gpf-errors-alert [errors]="errors"></gpf-errors-alert>
    </div>
    <div *ngIf="regressionNames.length" class="col-lg-6" id="normalize-checkboxes-container">
      <span>Normalized by:</span>
      <div *ngFor="let regressionName of regressionNames">
        <label style="padding-bottom: 2px; padding-top: 2px">
          <input
            #checkboxes
            type="checkbox"
            [checked]="isNormalizedBy(regressions[regressionName].measure_name)"
            (change)="onNormalizeByChange(regressions[regressionName], $event)"
            [disabled]="
              measure === null ||
              regressions[regressionName].instrument_name + '.' + regressions[regressionName].measure_name ===
                measure.name
            " />
          <span style="margin-left: 5px">{{ regressions[regressionName].display_name }}</span>
        </label>
      </div>
    </div>
  </div>
</div>
