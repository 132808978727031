<div *ngIf="selectedGeneScores">
  <div class="row panel" id="gene-scores-panel">
    <div class="col-sm-9">
      <div id="select-help-wrapper">
        <div class="select-wrapper">
          <select class="form-control" [(ngModel)]="selectedGeneScores" [title]="selectedGeneScores.desc" required>
            <option *ngFor="let geneScores of geneScoresArray" [ngValue]="geneScores">{{ geneScores.desc }}</option>
          </select>
          <span class="material-symbols-outlined select-dropdown-icon">arrow_drop_down</span>
        </div>
        <gpf-helper-modal
          style="height: 32px; margin-left: 4px"
          [style.visibility]="this.geneScoresLocalState.score.help ? 'visible' : 'hidden'"
          [modalContent]="this.geneScoresLocalState.score.help"
          [isMarkdown]="true"></gpf-helper-modal>
      </div>
      <div style="margin-top: 5px">
        <a class="download-link" href="{{ downloadUrl }}">Download</a>
      </div>
    </div>

    <div class="col-sm-9">
      <gpf-histogram
        *ngIf="selectedGeneScores"
        [width]="650"
        [height]="145"
        [bins]="selectedGeneScores.bins"
        [bars]="selectedGeneScores.bars"
        [logScaleX]="selectedGeneScores.logScaleX"
        [logScaleY]="selectedGeneScores.logScaleY"
        [smallValuesDesc]="selectedGeneScores.smallValuesDesc"
        [largeValuesDesc]="selectedGeneScores.largeValuesDesc"
        [rangesCounts]="rangesCounts | async"
        [(rangeStart)]="rangeStart"
        [(rangeEnd)]="rangeEnd">
      </gpf-histogram>
      <gpf-errors-alert [errors]="errors"></gpf-errors-alert>
    </div>
  </div>
</div>
<div *ngIf="!selectedGeneScores">
  <div class="form-block" style="display: contents">
    <div class="card">
      <ul ngbNav #nav="ngbNav" class="navbar bg-light nav-pills" style="justify-content: center">
        <li ngbNavItem>
          <span style="opacity: 75%"><i>No gene scores available</i></span>
        </li>
      </ul>
    </div>
  </div>
</div>
