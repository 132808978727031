<div class="pedigree-container" (click)="openModal()">
  <gpf-pedigree-chart
    [family]="family"
    [groupName]="groupName"
    [counterId]="counterId"
    [maxWidth]="pedigreeMaxWidth"
    [maxHeight]="pedigreeMaxHeight">
  </gpf-pedigree-chart>
</div>

<ng-template #pedigreeModal let-modal>
  <ng-container *ngIf="!modalSimpleView ? familyIdsList : true">
    <div id="modal-pedigree-container">
      <gpf-pedigree-chart [scale]="pedigreeScale" [family]="family" [groupName]="groupName" [counterId]="counterId">
      </gpf-pedigree-chart>
    </div>

    <ng-container *ngIf="!modalSimpleView">
      <div class="modal-family">
        <div class="pedigree-download-panel">
          <span id="modal-family-count">{{ familyIdsList.length }}</span>
          <span style="padding-left: 5px">
            <form
              (ngSubmit)="onSubmit($event)"
              action="{{ configService.baseUrl }}common_reports/family_counters/download"
              method="post">
              <input name="queryData" type="hidden" />
              <button class="btn btn-md btn-primary btn-right" id="download-button" type="submit">
                <span class="material-symbols-outlined">download</span>
              </button>
            </form>
          </span>
        </div>
        <hr />

        <div id="family-ids-list-wrapper">
          <div id="family-ids-list">
            <div>{{ familyIdsList | join : ', ' }}</div>
          </div>
        </div>
      </div>
    </ng-container>
  </ng-container>
</ng-template>
