<div class="card">
  <div class="card-header card-title gene-plot-header">
    <div class="header-item">
      <span>
        <button
          type="button"
          id="undo-button"
          class="gene-plot-header-icon"
          (click)="undo()"
          [disabled]="!zoomHistory?.canGoBackward"
          title="Undo (Ctrl+Z or Z)">
          <span class="material-symbols-outlined">arrow_back</span>
        </button>
        <button
          type="button"
          id="redo-button"
          class="gene-plot-header-icon"
          (click)="redo()"
          [disabled]="!zoomHistory?.canGoForward"
          title="Redo (Ctrl+Y or Y)">
          <span class="material-symbols-outlined">arrow_forward</span>
        </button>
        <button
          type="button"
          id="reset-button"
          class="gene-plot-header-icon"
          (click)="reset()"
          title="Reset (Double-click or 5)">
          <span class="material-symbols-outlined">refresh</span>
        </button>
      </span>

      <span style="position: relative; top: 1px">
        <label>
          <input type="checkbox" [checked]="!showTranscripts" (change)="showTranscripts = !showTranscripts; redraw()" />
          <span class="checkbox-option">Hide transcripts</span>
        </label>
        <label>
          <input #checkbox type="checkbox" [checked]="condenseIntrons" (change)="toggleCondenseIntrons()" />
          <span class="checkbox-option">Condense introns</span>
        </label>
      </span>
    </div>

    <span id="gene-title" class="header-item" style="font-size: 20px; font-weight: bold">
      <span [attr.title]="'Gene symbol: ' + gene.geneSymbol + '\n' + chromosomesTitle">{{ gene?.geneSymbol }}</span>
    </span>

    <span id="counters" class="header-item">
      <span title="Summary alleles">
        <span class="d-none d-xl-inline">Summary alleles:</span>
        <span class="d-none d-md-inline d-xl-none d-xxl-none">SA:</span>
        <span id="summary-alleles-count">
          <span>{{ this.variantsArray?.totalSummaryAllelesCount }} / {{ this.summaryVariantsCount }}</span>
        </span>
      </span>

      <div *ngIf="selectedGene">
        <button
          class="btn btn-md btn-primary btn-right"
          [disabled]="!this.variantsArray?.totalSummaryAllelesCount"
          id="download-summary-variants-button"
          (click)="onDownload()"
          title="Download summary variants">
          <span class="material-symbols-outlined">download</span>
        </button>
      </div>
    </span>
  </div>
  <div id="svg-container"></div>
</div>
