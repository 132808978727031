<ng-template #confirmation let-options="options">
  <div [ngClass]="['popover', 'show', 'bs-popover-' + options.placement, options.popoverClass]">
    <div class="arrow" id="arrow-id"></div>
    <h3 class="popover-header" [innerHTML]="options.title"></h3>
    <div class="popover-body">
      <p [innerHTML]="options.popoverMessage"></p>
      <div class="row">
        <div class="col-xs-12 col-12 text-center">
          <button
            *ngIf="!options.hideConfirmButton"
            [mwlFocus]="options.focusButton === 'confirm'"
            [class]="'btn btn-' + options.confirmButtonType"
            (click)="options.onConfirm({ clickEvent: $event })"
            [attr.title]="options.confirmText"
            [innerHtml]="options.confirmText"></button>
          <button
            *ngIf="!options.hideCancelButton"
            [mwlFocus]="options.focusButton === 'cancel'"
            [class]="'btn btn-' + options.cancelButtonType"
            (click)="options.onCancel({ clickEvent: $event })"
            [attr.title]="options.cancelText"
            [innerHtml]="options.cancelText"></button>
        </div>
      </div>
    </div>
  </div>
</ng-template>
<button
  *ngIf="!hide"
  id="confirm-button"
  mwlConfirmationPopover
  [confirmText]="confirmText"
  [title]="title"
  [customTemplate]="confirmation"
  [popoverMessage]="message"
  (confirm)="onClick($event)">
  <span [ngClass]="iconStyle.class">{{ iconStyle.name }}</span>
</button>
