<svg:line [attr.stroke]="color" stroke-width="2" x1="0" [attr.y1]="startY" x2="0" [attr.y2]="endY"></svg:line>

<svg:line [attr.stroke]="color" stroke-width="2" x1="-5" [attr.y1]="startY" x2="5" [attr.y2]="startY"></svg:line>

<svg:line [attr.stroke]="color" stroke-width="2" x1="-5" [attr.y1]="endY" x2="5" [attr.y2]="endY"></svg:line>

<svg:circle
  [attr.stroke]="color"
  [attr.fill]="fillColor"
  stroke-width="2"
  cx="0"
  [attr.cy]="centerY"
  r="3"></svg:circle>
