<div *ngIf="selectedGeneSetsCollection">
  <div class="row panel" id="gene-sets-panel" style="margin-left: 0px; margin-right: 0px">
    <div class="col-sm-3">
      <div class="select-wrapper">
        <select
          class="row-sm-3 form-control"
          title="{{ selectedGeneSetsCollection.desc }}"
          [(ngModel)]="selectedGeneSetsCollection"
          required
          id="selected-collection">
          <option *ngFor="let collection of geneSetsCollections" [ngValue]="collection">{{ collection.desc }}</option>
        </select>
        <span class="material-symbols-outlined select-dropdown-icon">arrow_drop_down</span>
      </div>
      <ngb-accordion
        *ngIf="selectedGeneSetsCollection?.types"
        activeIds="{{ defaultSelectedDenovoGeneSetId }}"
        [destroyOnHide]="false"
        #acc="ngbAccordion"
        class="gene-sets-types row-sm-3">
        <ngb-panel
          *ngFor="let type of selectedGeneSetsCollection.types"
          id="{{ type.datasetId }}-{{ type.personSetCollectionId }}-denovo-geneset">
          <ng-template ngbPanelTitle>
            <div style="text-align: left">
              <span style="display: block" class="btn-sm text-wrap"
                >{{ type.datasetName }}: {{ type.personSetCollectionName }}</span
              >
            </div>
          </ng-template>
          <ng-template ngbPanelContent>
            <div *ngFor="let personSet of type.personSetCollectionLegend">
              <label [attr.for]="type.datasetId + '-checkbox-' + personSet.id">
                <input
                  type="checkbox"
                  [attr.id]="type.datasetId + '-checkbox-' + personSet.id"
                  [ngModel]="isSelectedGeneType(type.datasetId, type.personSetCollectionId, personSet.id)"
                  (ngModelChange)="
                    setSelectedGeneType(type.datasetId, type.personSetCollectionId, personSet.id, $event); onSearch()
                  " />
                <span class="pedigree-icon" [style.background-color]="personSet.color"></span>
                <span>{{ personSet.name }}</span>
              </label>
            </div>
          </ng-template>
        </ngb-panel>
      </ngb-accordion>
    </div>

    <div class="col-sm-9">
      <fieldset
        style="display: inline-flex; flex-wrap: nowrap; width: 100%"
        matAutocompleteOrigin
        #geneSetsSearch="matAutocompleteOrigin"
        [disabled]="
          selectedGeneSetsCollection?.types?.length > 0 && object.keys(geneSetsLocalState.geneSetsTypes).length === 0
        ">
        <span
          style="width: 100%"
          [hidden]="!selectedGeneSet"
          id="selected-value"
          class="clickable"
          (click)="reset(); openCloseDropdown()">
          <span>{{
            selectedGeneSet ? selectedGeneSet?.name + ' (' + selectedGeneSet?.count + '): ' + selectedGeneSet?.desc : ''
          }}</span>
        </span>
        <div [hidden]="selectedGeneSet" style="display: inline-flex; flex-wrap: nowrap; width: 100%">
          <input
            matInput
            [matAutocomplete]="geneSetsDropdown"
            [matAutocompleteConnectedTo]="geneSetsSearch"
            #dropdownTrigger="matAutocompleteTrigger"
            #searchSetsBox
            id="search-box"
            style="width: 100%"
            autocomplete="off"
            [(ngModel)]="searchQuery"
            (keyup)="onKeyboardEvent($event)"
            placeholder="Select or start typing to search"
            (click)="reset()"
            spellcheck="false" />
          <mat-autocomplete autoActiveFirstOption #geneSetsDropdown="matAutocomplete" class="sets-dropdown">
            <mat-option [disabled]="true" class="status-info" *ngIf="isLoading && geneSets.length === 0"
              >Loading...</mat-option
            >
            <mat-option
              [disabled]="true"
              class="status-info"
              *ngIf="!isLoading && geneSets.length === 0 && searchSetsBox.value !== ''"
              >Nothing found
            </mat-option>
            <mat-option
              *ngFor="let element of geneSets"
              class="dropdown-item"
              type="button"
              (click)="onSelect(element)"
              (onSelectionChange)="onSelect(element)"
              value="{{ element.name }} ({{ element.count }}): {{ element.desc }}"
              title="{{ element.name }} ({{ element.count }}): {{ element.desc }}">
              <span
                innerHtml="{{ element.name | boldMatching : searchQuery }} ({{ element.count }}): {{
                  element.desc | boldMatching : searchQuery
                }}">
              </span>
            </mat-option>
          </mat-autocomplete>
        </div>
        <button
          style="border-top-left-radius: 0; border-bottom-left-radius: 0"
          type="button"
          class="dropdown-toggle btn btn-secondary dropdown-toggle-split"
          (click)="openCloseDropdown()">
        </button>
      </fieldset>
      <div *ngIf="selectedGeneSet">
        <span style="margin-right: 5px">Count: {{ selectedGeneSet.count }}</span>
        <span>(<a class="download-link" [href]="getDownloadLink()">Download</a>)</span>
      </div>
      <gpf-errors-alert [errors]="errors"></gpf-errors-alert>
    </div>
  </div>
</div>

<div *ngIf="geneSetsLoaded === null">
  <div class="form-block" style="display: contents">
    <div class="card">
      <ul ngbNav #nav="ngbNav" class="navbar bg-light nav-pills" style="justify-content: center">
        <li ngbNavItem>
          <span style="opacity: 75%"><i>Loading gene sets...</i></span>
        </li>
      </ul>
    </div>
  </div>
</div>
<div *ngIf="!selectedGeneSetsCollection && geneSetsLoaded !== null">
  <div class="form-block" style="display: contents">
    <div class="card">
      <ul ngbNav #nav="ngbNav" class="navbar bg-light nav-pills" style="justify-content: center">
        <li ngbNavItem>
          <span style="opacity: 75%"><i>No gene sets collections available</i></span>
        </li>
      </ul>
    </div>
  </div>
</div>
