<div class="panel" id="regions-filter-panel">
  <textarea
    #textArea
    #regionsFilterInput="ngModel"
    class="form-control"
    type="text"
    [(ngModel)]="regionsFilter.regionsFilter"
    (ngModelChange)="setRegionsFilter($event)"
    spellcheck="false">
  </textarea>

  <gpf-errors-alert [errors]="errors"></gpf-errors-alert>
</div>
