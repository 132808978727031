<gpf-histogram
  *ngIf="histogramData && measureName"
  [width]="650"
  [height]="145"
  [bins]="histogramData.bins"
  [bars]="histogramData.bars"
  [rangesCounts]="rangesCounts"
  [(rangeStart)]="rangeStart"
  [(rangeEnd)]="rangeEnd">
</gpf-histogram>
