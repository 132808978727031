<div *ngIf="selectedDataset" class="container">
  <gpf-genes-block></gpf-genes-block>
  <gpf-regions-block [genome]="selectedDataset.genome"></gpf-regions-block>
  <gpf-genotype-block [dataset]="selectedDataset"></gpf-genotype-block>
  <gpf-study-filters-block *ngIf="selectedDataset?.genotypeBrowserConfig?.hasStudyFilters" [dataset]="selectedDataset">
  </gpf-study-filters-block>
  <gpf-genomic-scores-block></gpf-genomic-scores-block>
  <gpf-family-filters-block
    *ngIf="selectedDataset?.genotypeBrowserConfig?.hasFamilyFilters"
    [dataset]="selectedDataset"
    [genotypeBrowserState]="genotypeBrowserState">
  </gpf-family-filters-block>
  <gpf-person-filters-block
    *ngIf="selectedDataset?.genotypeBrowserConfig?.hasPersonFilters"
    [dataset]="selectedDataset"
    [genotypeBrowserState]="genotypeBrowserState">
  </gpf-person-filters-block>

  <gpf-unique-family-variants-filter></gpf-unique-family-variants-filter>

  <div class="form-block">
    <form
      id="dl-form"
      (ngSubmit)="onSubmit($event)"
      action="{{ configService.baseUrl }}genotype_browser/query"
      method="post">
      <input name="queryData" type="hidden" />
      <button
        class="btn btn-md btn-primary btn-right"
        id="download-button"
        [disabled]="disableQueryButtons"
        type="submit"
        >Download</button
      >
    </form>
    <div class="button">
      <input
        type="button"
        [disabled]="disableQueryButtons"
        class="btn btn-md btn-primary"
        id="table-preview-button"
        value="Table Preview"
        (click)="submitQuery()" />
      <gpf-save-query [disabled]="disableQueryButtons" queryType="genotype"></gpf-save-query>
    </div>
  </div>
</div>
<div class="genotype-browser-results" *ngIf="genotypePreviewVariantsArray">
  <hr />
  <gpf-loading-spinner
    *ngIf="variantsCountDisplay"
    [loadingFinished]="loadingFinished"
    [count]="variantsCountDisplay"
    [verboseMode]="true">
  </gpf-loading-spinner>

  <gpf-genotype-preview-table
    *ngIf="showTable"
    [columns]="selectedDataset?.genotypeBrowserConfig?.tableColumns"
    [genotypePreviewVariantsArray]="genotypePreviewVariantsArray"
    [legend]="legend">
  </gpf-genotype-preview-table>
</div>
