<div class="genes-block form-block">
  <div class="card">
    <ul ngbNav #nav="ngbNav" (navChange)="onNavChange()" class="navbar bg-light nav-pills" id="list">
      <div class="nav-title-custom">Genes</div>
      <li [ngbNavItem]="'allGenes'" *ngIf="showAllTab">
        <a ngbNavLink>All</a>
      </li>
      <li [ngbNavItem]="'geneSymbols'" id="gene-symbols">
        <a ngbNavLink>Gene Symbols</a>
        <ng-template ngbNavContent>
          <gpf-gene-symbols></gpf-gene-symbols>
        </ng-template>
      </li>
      <li [ngbNavItem]="'geneSets'" id="gene-sets">
        <a ngbNavLink>Gene Sets</a>
        <ng-template ngbNavContent>
          <gpf-gene-sets></gpf-gene-sets>
        </ng-template>
      </li>
      <li [ngbNavItem]="'geneScores'" id="gene-scores">
        <a ngbNavLink>Gene Scores</a>
        <ng-template ngbNavContent>
          <gpf-gene-scores width="650" height="200"></gpf-gene-scores>
        </ng-template>
      </li>
    </ul>
    <div [ngbNavOutlet]="nav" class="card-block"></div>
  </div>
</div>
