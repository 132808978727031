<div *ngIf="selectedDatasetId" class="container" style="margin-bottom: 20px">
  <gpf-genes-block [showAllTab]="false"></gpf-genes-block>
  <gpf-enrichment-models-block [selectedDatasetId]="selectedDatasetId"></gpf-enrichment-models-block>

  <div class="form-block button" style="display: inline-flex">
    <input
      id="enrichment-test-button"
      [disabled]="disableQueryButtons"
      class="btn btn-md btn-primary"
      type="button"
      value="Enrichment Test"
      (click)="submitQuery()" />
    <gpf-save-query [disabled]="disableQueryButtons" queryType="enrichment"></gpf-save-query>
  </div>
  <gpf-enrichment-table [enrichmentResults]="enrichmentResults"></gpf-enrichment-table>
</div>
