<div class="genotype-block form-block">
  <div class="row">
    <div class="col-lg-3">
      <gpf-present-in-parent></gpf-present-in-parent>
    </div>
    <div class="col-lg-9">
      <gpf-pheno-tool-effect-types [variantTypes]="variantTypes"></gpf-pheno-tool-effect-types>
    </div>
  </div>
</div>
