<div class="container">
  <div class="card">
    <div id="pheno-instrument-block">
      <div class="select-wrapper">
        <select
          class="form-control"
          id="select-instrument"
          title="{{ selectedInstrument$.value }}"
          [ngModel]="selectedInstrument$ | async"
          (ngModelChange)="emitInstrument($event)">
          <option value="">All instruments</option>
          <option *ngFor="let instrument of (instruments | async)?.instruments" [ngValue]="instrument">{{
            instrument
          }}</option>
        </select>
        <span class="material-symbols-outlined select-dropdown-icon">arrow_drop_down</span>
      </div>
      <div class="search-input-wrapper">
        <label id="search-term-label" for="search-term">
          <span class="material-symbols-outlined search-icon">search</span>
        </label>
        <input
          placeholder="Search"
          [ngModel]="input$ | async"
          (input)="search(searchBox.value)"
          #searchBox
          id="search-term"
          class="search-input form-control"
          type="text"
          autocomplete="off"
          spellcheck="false" />
        <span
          class="material-symbols-outlined md search-clear-icon"
          *ngIf="searchBox.value !== ''"
          (click)="searchBox.value = ''; search(searchBox.value)">close</span
        >
      </div>
      <div id="download-wrapper">
        <span>Phenotype measures</span>
        <input name="queryData" type="hidden" />
        <button (click)="downloadMeasures()" id="download-measures" class="btn btn-md btn-primary btn-right">
          <span class="material-symbols-outlined">download</span>
        </button>
      </div>
    </div>
  </div>
</div>

<div *ngIf="measuresToShow" class="col-lg-12" style="padding: 0 30px">
  <gpf-pheno-browser-table [measures]="measuresToShow"></gpf-pheno-browser-table>
</div>

<div *ngIf="errorModal">
  <div style="display: flex; align-items: center" class="modal" role="dialog" aria-hidden="true">
    <div class="modal-dialog warning-modal">
      <div class="modal-content">
        <div class="modal-header" style="border-bottom: 0">
          <h5 class="modal-title">Warning</h5>
        </div>
        <div class="modal-body">Too many instruments, select less than 1900!</div>
        <div class="modal-footer" style="border-top: 0">
          <button type="button" class="btn btn-secondary" (click)="errorModalBack()">Back</button>
        </div>
      </div>
    </div>
  </div>
</div>
