<span class="holder">
  <div style="width: 100%; height: 100%">
    <div style="width: 100%; height: 100%; display: table">
      <div style="width: 100%; height: 100%; display: table-cell; vertical-align: middle">
        <div (click)="onSortClick(columnInfo)" [class.clickable]="columnInfo.sortable" id="sort">
          <ng-template *ngIf="columnInfo.contentTemplateRef" [ngTemplateOutlet]="columnInfo.contentTemplateRef">
          </ng-template>
          <span *ngIf="columnInfo.caption">{{ columnInfo.caption }}</span>
        </div>
        <div (click)="onSortClick(columnInfo)" [class.clickable]="columnInfo.sortable" id="sorting-buttons">
          <span
            [hidden]="!(columnInfo.sortable && sortingInfo?.sortBySubcolumn !== columnInfo)"
            id="bg"
            class="material-symbols-outlined"
            >unfold_more</span
          >
          <span
            [hidden]="
              !(columnInfo.sortable && sortingInfo?.sortBySubcolumn === columnInfo && sortingInfo?.sortOrderAsc)
            "
            id="asc"
            class="material-symbols-outlined"
            >expand_less</span
          >
          <span
            [hidden]="
              !(columnInfo.sortable && sortingInfo?.sortBySubcolumn === columnInfo && !sortingInfo?.sortOrderAsc)
            "
            id="desc"
            class="material-symbols-outlined"
            >expand_more</span
          >
        </div>
      </div>
    </div>
  </div>
  <div style="float: right; clear: right; margin-right: 0px; height: 100%">
    <div style="height: 100%; display: table">
      <div *ngFor="let child of columnInfo.subcolumnsChildren" style="display: table-row">
        <div (click)="onSortClick(child)" [class.clickable]="child.sortable" id="sort-child">
          <ng-template *ngIf="child.contentTemplateRef" [ngTemplateOutlet]="child.contentTemplateRef"></ng-template>
          <span *ngIf="!child.contentTemplateRef">{{ child.caption }}</span>
        </div>
        <div (click)="onSortClick(child)" [class.clickable]="child.sortable" id="sorting-buttons">
          <span
            [hidden]="!(child.sortable && sortingInfo?.sortBySubcolumn !== child)"
            id="bg"
            class="material-symbols-outlined"
            >unfold_more</span
          >
          <span
            [hidden]="!(child.sortable && sortingInfo?.sortBySubcolumn === child && sortingInfo?.sortOrderAsc)"
            id="asc"
            class="material-symbols-outlined"
            >expand_less</span
          >
          <span
            [hidden]="!(child.sortable && sortingInfo?.sortBySubcolumn === child && !sortingInfo?.sortOrderAsc)"
            id="desc"
            class="material-symbols-outlined"
            >expand_more</span
          >
        </div>
      </div>
    </div>
  </div>
</span>
