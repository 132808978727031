<div class="table-toolbar">
  <input
    #credentialNameBox
    placeholder="New credential"
    autocomplete="off"
    id="new-credential-name-box"
    class="search-input form-control my-0 py-1"
    type="search"
    pattern=".{3,}"
    (focusout)="creationError = ''"
    required
    spellcheck="false" />
  <button #createButton id="create-credential-button" class="btn btn-primary">Create</button>
  <div *ngIf="creationError" class="creation-error alert alert-danger">{{ creationError }}</div>
</div>

<ng-template #credentialModal let-modal id="credential-modal">
  <div id="credential-modal-title-wrapper">
    <div id="credential-modal-title">Credentials: shown only once, copy before closing.</div>
    <button
      [cdkCopyToClipboard]="temporaryShownCredentials"
      title="Copy credentials"
      id="copy-credentials-button"
      class="action-button">
      <span class="material-symbols-outlined">content_copy</span>
      Copy
    </button>
  </div>
  <div class="card-block" id="credential-modal-content">{{ temporaryShownCredentials }}</div>
</ng-template>

<div class="grid-container" *ngIf="credentials">
  <div class="grid-header">Name</div>
  <div class="grid-header">Actions</div>
  <ng-container *ngFor="let credential of credentials">
    <div class="grid-cell" [attr.id]="credential.name + '-name-cell'">
      <div>
        <span *ngIf="credential.name !== '' && currentCredentialEdit !== credential.name" id="credential-name">{{
          credential.name
        }}</span>
        <span
          *ngIf="currentCredentialEdit !== credential.name"
          (click)="currentCredentialEdit = credential.name"
          id="edit-icon"
          class="material-symbols-outlined"
          title="Edit"
          >edit</span
        >
        <div *ngIf="currentCredentialEdit === credential.name">
          <div *ngIf="renameError" id="rename-error">{{ renameError }}</div>
          <div id="edit-wrapper">
            <input
              #credentialRename
              type="text"
              [value]="credential.name"
              spellcheck="false"
              required
              class="name-field search-input form-control my-0 py-1"
              [attr.id]="credential.name + '-new-name-input'"
              (keydown.enter)="edit(credential, credentialRename.value)"
              (keydown.escape)="currentCredentialEdit = ''" />
            <button id="cancel-button" (click)="currentCredentialEdit = ''" title="Cancel">
              <span class="material-symbols-outlined sm bold red">close</span></button
            >
          </div>
        </div>
      </div>
    </div>

    <div class="grid-cell" [attr.id]="credential.name + '-actions-cell'">
      <gpf-confirm-button
        [attr.id]="credential.name + '-delete-credential-button'"
        (clicked)="deleteCredential(credential.name)"
        confirmText="Delete"
        title="Delete credential"
        [message]="'Credential \'' + credential.name + '\' will be deleted. This action is irrevertible!'"
        [iconStyle]="{ name: 'close', class: 'material-symbols-outlined bold' }">
      </gpf-confirm-button>
    </div>
  </ng-container>
</div>
