<div *ngIf="!modelsLoaded">
  <div class="form-block" style="display: contents">
    <ul ngbNav #nav="ngbNav" class="bg-light nav-pills" style="justify-content: center">
      <li ngbNavItem>
        <span style="opacity: 75%"><i>Loading models...</i></span>
      </li>
    </ul>
  </div>
</div>
<div *ngIf="backgrounds || countings" style="display: flex; justify-content: space-evenly">
  <div *ngIf="backgrounds" id="background-models" style="width: 300px; flex: 1; padding-right: 16px">
    <span>Background Models:</span>
    <div class="select-wrapper">
      <select
        class="form-control"
        title="{{ background.description }}"
        style="width: 100%; text-overflow: ellipsis"
        [ngModel]="background"
        (ngModelChange)="changeBackground($event)">
        <option *ngFor="let background of backgrounds" [ngValue]="background">{{ background.name }}</option>
      </select>
      <span class="material-symbols-outlined select-dropdown-icon">arrow_drop_down</span>
    </div>
  </div>
  <div *ngIf="countings" id="counting-models" style="width: 300px; flex: 1">
    <span>Counting Models:</span>
    <div class="select-wrapper">
      <select
        class="form-control"
        title="{{ counting.description }}"
        style="width: 100%; text-overflow: ellipsis"
        [ngModel]="counting"
        (ngModelChange)="changeCounting($event)">
        <option *ngFor="let counting of countings" [ngValue]="counting">{{ counting.name }}</option>
      </select>
      <span class="material-symbols-outlined select-dropdown-icon">arrow_drop_down</span>
    </div>
  </div>

  <gpf-errors-alert [errors]="errors"></gpf-errors-alert>
</div>
