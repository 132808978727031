<div *ngFor="let i of subheadersCount" style="display: table-row">
  <gpf-table-view-header-cell
    #cell
    *ngFor="let column of columns"
    [columnInfo]="column.headerChildren.toArray()[i]"
    [sortingInfo]="sortingInfo"
    [style.width]="column.columnWidth || null"
    [style.min-width]="column.columnWidth || null"
    [style.max-width]="column.columnWidth || null"
    (sortingInfoChange)="sortingInfoChange.emit($event)"
    style="vertical-align: middle">
  </gpf-table-view-header-cell>
</div>
