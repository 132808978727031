<div>
  <gpf-checkbox-list
    [title]="'Inheritance Types'"
    [items]="inheritanceTypes"
    [selectedItems]="selectedValues"
    [displayNames]="inheritanceTypeDisplayNames"
    (itemsUpdateEvent)="updateInheritanceTypes($event)">
  </gpf-checkbox-list>
  <gpf-errors-alert [errors]="errors"></gpf-errors-alert>
</div>
