<div
  class="svg-container"
  [style.width.px]="width * scale"
  [style.max-width.px]="maxWidth ? maxWidth : width * scale"
  [style.max-height.px]="maxHeight">
  <svg [attr.viewBox]="getViewBox()">
    <svg:line
      *ngFor="let straightLine of straightLines"
      [attr.x1]="straightLine.startX"
      [attr.y1]="straightLine.startY"
      [attr.x2]="straightLine.endX"
      [attr.y2]="straightLine.endY"
      stroke="black"
      stroke-width="1" />

    <svg:path
      *ngFor="let curveLine of curveLines"
      [attr.d]="curveLine.path"
      fill="none"
      stroke="black"
      stroke-width="1" />

    <svg:g
      gpf-pedigree-chart-member
      *ngFor="let pedigree of pedigreeDataWithLayout"
      [attr.transform]="
        'translate(' +
        pedigree.xUpperLeftCorner +
        ' ' +
        pedigree.yUpperLeftCorner +
        ') scale(' +
        pedigree.scaleFactor +
        ')'
      "
      [pedigreeData]="pedigree.individual.pedigreeData" />
  </svg>
</div>
