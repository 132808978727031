<div style="padding: 20px" id="tags-modal-content">
  <div id="tags-modal-header">
    <div>
      <span id="mode-label">Choose mode: </span>
      <button class="and-toggle-button" (click)="onChooseMode(true)" [ngClass]="{ 'selected-mode': tagIntersection }"
        >And</button
      >
      <button class="or-toggle-button" (click)="onChooseMode(false)" [ngClass]="{ 'selected-mode': !tagIntersection }"
        >Or</button
      >
    </div>
    <a (click)="clearFilters()" id="clear-filters-button">Clear filters</a>
  </div>

  <div
    id="tag-list"
    [ngStyle]="{
      display: 'grid',
      'grid-template-columns': 'repeat(' + numOfCols + ', max-content)',
      'row-gap': '6px'
    }">
    <div *ngFor="let tag of tags" class="modal-content-lines">
      <span
        class="material-icons tags"
        [id]="tag + '-tag-add'"
        (click)="selectFilter(tag)"
        [ngStyle]="{ color: filtersButtonsState[tag] === 1 ? 'green' : '#cdcdcd' }"
        >add_circle</span
      >
      <span
        class="material-icons tags"
        [id]="tag + '-tag-remove'"
        (click)="deselectFilter(tag)"
        [ngStyle]="{ color: filtersButtonsState[tag] === -1 ? 'red' : '#cdcdcd' }"
        >remove_circle</span
      >
      <span [attr.for]="tag + '-tag'" id="tag-label">{{ tag }}</span>
    </div>
  </div>
</div>
