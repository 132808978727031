<div class="container" style="margin-bottom: 20px">
  <gpf-genes-block [showAllTab]="true"></gpf-genes-block>
  <gpf-pheno-tool-measure></gpf-pheno-tool-measure>
  <gpf-pheno-tool-genotype-block [variantTypes]="variantTypesSet"></gpf-pheno-tool-genotype-block>

  <gpf-family-filters-block
    *ngIf="selectedDataset?.genotypeBrowserConfig?.hasFamilyFilters"
    [dataset]="selectedDataset"
    [genotypeBrowserState]="phenoToolState">
  </gpf-family-filters-block>

  <div class="form-block">
    <div class="btn-toolbar justify-content-between" role="toolbar" aria-label="Toolbar with button groups">
      <div class="button">
        <button
          id="report-button"
          type="button"
          class="btn btn-md btn-primary"
          (click)="submitQuery()"
          [disabled]="disableQueryButtons">
          Report
        </button>
        <gpf-save-query [disabled]="disableQueryButtons" queryType="phenotool"></gpf-save-query>
      </div>
      <div class="btn-group">
        <form (ngSubmit)="onDownload($event)" action="{{ configService.baseUrl }}pheno_tool/download" method="post">
          <input name="queryData" type="hidden" />
          <button
            class="btn btn-md btn-primary"
            [disabled]="disableQueryButtons"
            type="submit"
            id="download-pheno-report-button"
            >Download</button
          >
        </form>
      </div>
    </div>
  </div>
</div>
<div *ngIf="phenoToolResults" class="pheno-tool-results">
  <gpf-pheno-tool-results-chart [phenoToolResults]="phenoToolResults"></gpf-pheno-tool-results-chart>
</div>
