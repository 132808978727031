<div class="gender-card card">
  <div class="card-header card-title">Carrier Gender</div>
  <div class="card-block">
    <div class="btn-group btn-group-sm">
      <button (click)="selectAll()" class="btn btn-default">All</button>
      <button (click)="selectNone()" class="btn btn-default">None</button>
    </div>
    <div class="checkbox">
      <label>
        <input
          #maleCheckbox
          type="checkbox"
          [checked]="gender.male"
          (change)="genderCheckValue('male', maleCheckbox.checked)" />
        <span class="gender-icon male"></span>
      </label>
    </div>
    <div class="checkbox">
      <label>
        <input
          #femaleCheckbox
          type="checkbox"
          [checked]="gender.female"
          (change)="genderCheckValue('female', femaleCheckbox.checked)" />
        <span class="gender-icon female"></span>
      </label>
    </div>
    <div class="checkbox">
      <label>
        <input
          #unspecifiedCheckbox
          type="checkbox"
          [checked]="gender.unspecified"
          (change)="genderCheckValue('unspecified', unspecifiedCheckbox.checked)" />
        <span class="gender-icon unspecified"></span>
      </label>
    </div>
  </div>

  <gpf-errors-alert [errors]="errors"></gpf-errors-alert>
</div>
