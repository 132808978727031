<div *ngIf="pedigree" class="pedigree-cell" (click)="openModal()">
  <gpf-pedigree
    [modalSimpleView]="false"
    [family]="pedigree.data"
    [groupName]="pedigree.groupName"
    [counterId]="pedigree.counterId"
    [pedigreeMaxWidth]="pedigreeMaxWidth">
  </gpf-pedigree>
  <span class="pedigree-count">{{ pedigree.count }}</span>
</div>
